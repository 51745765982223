/* sparkline chart */
#jqstooltip {
	background-color: var(--font-white);
	border-color: var(--border-color);
	color: var(--font-color);

	.jqsfield {
		color: var(--font-color);
	}
}

/* chartist */
$ct-series-colors: (
	a: #6ebdd1,
	b: #f9ab6c,
	c: #afc979,
	d: #AB7DF6,
	e: #5cc196,
	f: #d17905,
	g: #453d3f,
	h: #59922b,
	i: #0544d3,
	j: #6b0392,
	k: #f05b4f,
	l: #dda458,
	m: #eacf7d,
	n: #86797d,
	o: #b2c326,
);

.ct-chart {
	position: relative;
}

.ct-series {
	.ct-line {
		stroke-width: 3px;
	}
	.ct-bar {
		stroke-width: 15px;
	}
	.ct-point {
		stroke-width: 10px;
		stroke-linecap: circle;
	}
}

@each $series-name, $series-color in $ct-series-colors {
	.ct-series-#{$series-name} {
		.ct-line,
		.ct-bar,
		.ct-point {
			stroke: $series-color;
		}

		.ct-area, 
		.ct-slice-donut-solid, 
		.ct-slice-pie,
		.ct-bar {
			fill: $series-color;
		}
	}
}

.chartist-tooltip {
	@include border-radius(3px);

	min-width: 3.5em;
	background: var(--font-color);
	color: var(--font-white);

	&:before {
		margin-left: -8px;
		border-width: 8px;
		border-top-color: var(--font-color);;
	}
}

.ct-axis-title {
	fill: var(--font-ccc);
}


/* Top Products Chart */
.ct-label {
	font-size: 14px;
	color: var(--font-color);
}
.ct-legend {
	position: relative;	
	z-index: 10;
	text-align: right;
	padding: 0;

	li {
		@include inline-block;
		position: relative;
		padding-left: 18px;
		margin-bottom: 3px;
		margin-right: 20px;
		list-style-type: none;
	}

	li:before {
		width: 12px;
		height: 12px;
		position: absolute;
		top: 5px;
		left: 0;
		content: '';
		border: 3px solid transparent;
		border-radius: 2px;
	}

	li.inactive:before {
		background: transparent;
	}

	&.ct-legend-inside {
		position: absolute;
		top: 0;
		right: 0;
	}

	$i: 0;
	@each $series-name, $series-color in $ct-series-colors {
		.ct-series-#{$i}:before {
			background-color: $series-color;
			border-color: $series-color;
		}

		$i: $i + 1;
	}
}
/* End Top Products Chart */
.ct-grid{
    stroke: var(--border-color);       
}
.user_statistics{
    .ct-series{
        .ct-point{
            stroke-width: 3px;
        }
        .ct-line{
            stroke-width: 1px;
        }
    }
    .ct-series-0:before{
        background-color: var(--white-color);
        border-color: $orange;
    }
    .ct-series-1:before{
        background-color: var(--white-color);
        border-color: $green;
    }
    .ct-series-2:before{
        background-color: var(--white-color);
        border-color: $blue;
    }
    .ct-series-3:before{
        background-color: var(--white-color);
        border-color: var(--dark-color);;
    }
    .ct-series-a{
        .ct-line,
        .ct-point{
            stroke: $orange;
        }
    }
    .ct-series-b{
        .ct-line,
        .ct-point{
            stroke: $green;
        }
    }
    .ct-series-c{
        .ct-line,
        .ct-point{
            stroke: $blue;
        }
    }
    .ct-series-d{
        .ct-line,
        .ct-point{
            stroke: var(--dark-color);
        }
    }
}


.c3-chart-arc{
	path{
		stroke: var(--border-color);
	}
}

.apexcharts-canvas{
	line{
		stroke: var(--border-color);
	}
	text{
		fill: var(--font-color);
	}
	circle{
		stroke: var(--border-color);
	}
	.apexcharts-legend-text{
		color: var(--font-color) !important;
	}
}

