// ul li animation
.li_animation_delay {
    > li {
        opacity: 0;
        transform: translateY(15px);
        -webkit-animation: eliment-animation 0.3s ease-in-out 0.33333s;
        animation: eliment-animation 0.3s ease-in-out 0.33333s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &:nth-child(1) {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(6) {
            -webkit-animation-delay: 0.35s;
            animation-delay: 0.35s;
        }
        &:nth-child(7) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        &:nth-child(8) {
            -webkit-animation-delay: 0.45s;
            animation-delay: 0.45s;
        }
        &:nth-child(9) {
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }
        &:nth-child(10) {
            -webkit-animation-delay: 0.55s;
            animation-delay: 0.55s;
        }
    }
}

.right_icon_bar {
    ul > li {
        opacity: 0;
        transform: translateX(15px);
        -webkit-animation: eliment-animation 0.3s ease-in-out 0.33333s;
        animation: eliment-animation 0.3s ease-in-out 0.33333s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &:nth-child(1) {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(6) {
            -webkit-animation-delay: 0.35s;
            animation-delay: 0.35s;
        }
        &:nth-child(7) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
    }
}

// top bar
.notifications {
    > li {
        opacity: 0;
        transform: translateY(15px);
        -webkit-animation: eliment-animation 0.3s ease-in-out 0.33333s;
        animation: eliment-animation 0.3s ease-in-out 0.33333s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &:nth-child(1) {
            transform: none;
            -webkit-animation-delay: none;
            animation-delay: none;
            opacity: 1;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
    }
}

// main sub menu
.metismenu {
    > li ul li {
        opacity: 0.3;
        transform: translateX(20px);
        -webkit-animation: eliment-animation 0.2s ease-in-out 0.33333s;
        animation: eliment-animation 0.2s ease-in-out 0.33333s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &:nth-child(1) {
            transform: none;
            -webkit-animation-delay: none;
            animation-delay: none;
            opacity: 1;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        &:nth-child(6) {
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }
        &:nth-child(7) {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }
        &:nth-child(8) {
            -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
        }
        &:nth-child(9) {
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }
        &:nth-child(10) {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
        }
        &:nth-child(11) {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }
        &:nth-child(12) {
            -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
        }
        &:nth-child(13) {
            -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
        }
        &:nth-child(14) {
            -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
        }
        &:nth-child(15) {
            -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
        }
    }
}

@-webkit-keyframes eliment-animation {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes eliment-animation {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
