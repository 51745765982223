﻿.media {
    @extend .m-b-20;

    &.mleft{
        .media-left{
            @extend .m-r-20;
        }
    }
    &.mright{
        .media-body{
            @extend .align-right;
        }
        .media-right{
            @extend .m-l-20;
        }
    }

    .media-body {
        color: var(--font-color);
        font-size: $font-size;

        .media-heading {
            @extend .margin-0;
            font-size: 16px;
            font-weight: $font-weight-700;           
            color: var(--heading-color);
        }
    }
}
.bs-example{
    .media{
        .media-left{
            @extend .m-r-20;
        }
    }
}
