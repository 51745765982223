/* Elements */
.separator-linethrough {
    @extend .align-center;
	position: relative;
	margin: 30px 0;

	span {
		@include inline-block;
		padding: 0 10px;
		position: inherit;
		font-size: 18px;
		background-color: var(--font-white);
		z-index: 9;
	}

	&:after {
		@extend .displayblock;
		position: absolute;
		top: 50%;
		width: 100%;
		content: '';
		border-top: 1px solid var(--border-color);;
		z-index: 0;
	}
}

.block-header{
    @extend .m-t-20;
    @extend .m-b-20;
    color: var(--dark-color);

	h2{
		font-size: 20px;
	}
	.breadcrumb{
        @extend .margin-0;
        @extend .padding-0;
        background: transparent;
        font-size: 13px;

        a{
            color: var(--text-muted);
        }
    }
}

/* toastr notification */
#toast-container {
	> div {
		@include opacity(1);
		
		&,
		&:hover{
			@include box-shadow(none);
		}
    }

    .toast-close-button {
        @include opacity(.3);
        color: var(--font-white);
        top: -11px;
        text-shadow: none;        
    
        &:hover,
        &:focus {
            @include opacity(.5);
        }
    }
    
    .toast-info {
        background-color: $color-info;
    
        .toast-close-button {
            color: var(--font-white);
        }
    }
    
    .toast-success {
        background-color: $color-success;
    
        .toast-close-button {
            color: darken($color-success, 20%);
        }
    }
    
    .toast-warning {
        background-color: $color-warning;
    
        .toast-close-button {
            color: darken($color-warning, 20%);
        }
    }
    
    .toast-error {
        background-color: $color-danger;
    
        .toast-close-button {
            color: darken($color-danger, 25%);
        }
    }

    .toast {
        @extend .m-b-0;
        a {
            text-decoration: underline;
    
            &:hover,
            &:focus {
                text-decoration: none;
                color: var(--font-white);
            }
        }
    
        .toast-message {
            font-size: 14px;
            line-height: 1.4;
        }
    }
}

.list-group-item{
    background-color: var(--card-color);
    border-color: var(--border-color);
}

.toggle-switch{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem;
    margin: 0;

    input {
        display: none;
        &:checked{
            + .toggle-switch-slider::before {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
            }
            + .toggle-switch-slider::before {
                -webkit-transform: translateX(1.525rem);
                -ms-transform: translateX(1.525rem);
                transform: translateX(1.525rem);
            }
            + .toggle-switch-slider {
                border: .0525rem solid var(--primary-color);
            }
        }
    }

    .toggle-switch-slider {
        @include transition(all .2s ease-in-out);
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: .0625rem solid var(--border-color);
        border-radius: .18rem;
        background-color: transparent;

        &:before {
            @include transition(all .2s ease-in-out);
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: .0625rem;
            bottom: 2px;
            border-radius: .18rem;
            background-color: var(--border-color);
        }
    }
    + span {
        width: calc(100% - 50px);
    }
}
.knob {
    margin-left: -79px !important;
    margin-right: auto !important;
}
.knob2 {
    margin-left: -79px !important;
    margin-right: auto !important;
}