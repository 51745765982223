/*           badges             */
.badge{
    @extend .m-l-5;
    @extend .m-r-5;
    padding: 4px 8px;
    text-transform: uppercase;    
    line-height: 12px;
    border: 1px solid transparent;
    font-weight: $font-weight-400;
    vertical-align: bottom;
    
    &.badge-icon{
        padding: 0.4em 0.55em;
        i{
           font-size: 0.8em;
        }
    }
    &.badge-default{
        background: transparent;
        border-color: var(--text-muted);
        color: var(--text-muted);
    }
    &.badge-primary{
        background: transparent;
        border-color: var(--primary-color);
        color: var(--primary-color);
    }
    &.badge-info{
        background: transparent;
        border-color: $color-info;
        color: $color-info;
    }
    &.badge-success{
        background: transparent;
        border-color: $color-success;
        color: $color-success;
    }
    &.badge-warning{
        background: transparent;
        border-color: $color-warning;
        color: $color-warning;
    }
    &.badge-danger{
        background: transparent;
        border-color: $color-danger;
        color: $color-danger;
    }
}

