﻿.wizard,
.tabcontrol {
    @extend .displayblock;
    width: 100%;
    overflow: hidden;
    a{
        outline: 0;
    }
    ul{
        @extend .padding-0;
        @extend .margin-0;
        list-style: none !important;
    }
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.tabcontrol > .content > .title {
    position: absolute;
    left: -999em;
}

.wizard {

    &.vertical {
        > .steps {
            float: left;
            width: 30%;
            @extend .p-r-5;

            > ul > li {
                float: none;
                width: 100%;

                a{
                    margin: 0 0 2px 0;
                }
            }
        }

        > .content {
            float: left;
            margin: 0 0 0.5em 0;
            width: 70%;
        }

        > .actions {
            float: right;
            width: 100%;
        }
        
    }

    ul > li, .tabcontrol ul > li {
        @extend .displayblock;
        @extend .padding-0;
    }

    > {
        .steps {
            @extend .displayblock;
            position: relative;
            width: 100%;
            margin-bottom: 2px;

            a {
                @extend .displayblock;
                width: auto;
                margin: 0 2px 0 0;
                padding: 1em 1em;
                text-decoration: none;                

                &:hover, &:active {
                    @extend .displayblock;
                    width: auto;
                    margin: 0 2px 0 0;
                    padding: 1em 1em;
                    text-decoration: none;
                }
            }
            .number{
                font-size: 14px;
            }
            .disabled a {
                background: var(--font-xlight);
                color: var(--font-ccc);
                cursor: default;

                &:hover, &:active {
                    background: var(--font-xlight);
                    color: var(--font-ccc);
                    cursor: default;
                }
            }
            .current a {
                background: var(--primary-color);
                color: var(--font-white);
                cursor: default;

                &:hover, &:active {
                    background: var(--primary-color);
                    color: var(--font-white);
                    cursor: default;
                }
            }
            .done a {
                background: var(--primary-color);                
                opacity: 0.7;

                &:hover, &:active {
                    background: var(--primary-color);
                    color: var(--font-white);
                    opacity: 0.7;
                }
            }
            .error a {
                background: $color-danger;
                color: var(--font-white);

                &:hover, &:active {
                    background: $color-danger;
                    color: var(--font-white);
                }
            }
            > ul > li {
                width: 25%;
                float: left;
            }
        }
        .content {
            @extend .displayblock;
            background: var(--white-color);
            margin: 0;
            border: 1px solid var(--border-color);
            min-height: 35em;
            overflow: hidden;
            position: relative;
            width: auto;
            > .body label.error{
                margin: 0;
                font-size: 12px;
            }
        }
        .actions{
            @extend .displayblock;
            @extend .align-right;
            @extend .align-right;
            @extend .m-t-5;
            position: relative;
            width: 100%;

            > ul > li {
                float: left;
            }
            ul{
                @extend .inlineblock;
            }

            a {
                @extend .displayblock;
                @extend .m-l-5;
                background: var(--primary-color);
                color: var(--font-white);
                text-decoration: none;
                border-radius: .25rem;                
                padding: 5px 10px;
                
                &:hover, &:active {
                    @extend .displayblock;
                    background: var(--primary-color);
                    color: var(--font-white);
                    text-decoration: none;                    
                }
            }

            .disabled a {
                background: var(--font-xlight);
                color: var(--font-ccc);

                &:hover, &:active {
                    background: var(--font-xlight);
                    color: var(--font-ccc);
                }
            }
        }
    }
    .content {
        min-height: 245px;        
        overflow-y: auto;

        .body {
            padding: 15px;
        }
    }
}

.tabcontrol > {
    .steps {
        position: relative;
        @extend .displayblock;
        width: 100%;

        > ul {
            position: relative;
            margin: 6px 0 0 0;
            top: 1px;
            z-index: 1;

            > li {
                float: left;
                margin: 5px 2px 0 0;
                padding: 1px;
                -webkit-border-top-left-radius: 5px;
                -webkit-border-top-right-radius: 5px;
                -moz-border-radius-topleft: 5px;
                -moz-border-radius-topright: 5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                &:hover {
                    background: var(--font-xlight);
                    border: 1px solid var(--font-ccc);
                    @extend .padding-0;
                }

                &.current {
                    background: var(--white-color);
                    border: 1px solid var(--font-ccc);
                    border-bottom: 0 none;
                    padding: 0 0 1px 0;
                    @extend .m-t-0;

                    > a {
                        padding: 15px 30px 10px 30px;
                    }
                }

                > a {
                    color: var(--font-color);
                    @extend .inlineblock;
                    @extend .margin-0;
                    border: 0 none;
                    padding: 10px 30px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .content {
        position: relative;
        @extend .inlineblock;
        width: 100%;
        height: 35em;
        overflow: hidden;
        border-top: 1px solid var(--font-ccc);
        @extend .p-t-20;

        > .body {
            float: left;
            position: absolute;
            width: 95%;
            height: 95%;
            padding: 2.5%;

            ul {
                list-style: disc !important;

                > li {
                    display: list-item;
                }
            }
        }
    }
}

@media only screen and (max-width: $break-small - 1px) {
    .wizard {
        > .steps{
            > ul > li{
                width: 100%;
            }
        }
        &.wizard.vertical{
            >.content, >.steps{
                width: 100%
            }
        }
    }
}
