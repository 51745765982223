/* Forms */

label {
	font-weight: 600;
}

.search-form {
	@extend .padding-0;
	position: relative;

	.form-control{
		height: 40px;
		border: 0;
		padding-left: 20px;
		background: transparent;
	}	
	i{
		font-size: 12px;
	}

	.btn {
		@include box-shadow(none);
		@extend .padding-0;
		position: absolute;
		top: 10px;
		left: 0;
		border: none;
		color: var(--font-color);
		background-color: transparent;

		&:hover,
		&:focus {
			background-color: inherit;
			border-left: none;
			border-color: inherit;
		}
	}
	@include max-screen($break-small - 1px) {
		display: none;
	}
}

/* fancy checkbox and radio button */
.fancy-checkbox {
	margin-right: 10px;

	&,
	label {
		font-weight: normal;
	}

	input[type="checkbox"] {
		display: none;
		
		+ span {
			@include inline-block;

			cursor: pointer;
			position: relative;

			&:before {
				@include inline-block;

				position: relative;
				bottom: 1px;
				width: 24px;
				height: 24px;
				margin-right: 10px;
				content: "";
				border: 1px solid var(--border-color);
				border-radius: 3px;
			}
		}
        
        &:checked + span:before {
			font-family: FontAwesome;
			content: '\f00c';
			font-size: 10px;
			color: var(--primary-color);
			border-color: var(--primary-color);;
			text-align: center;
            line-height: 21px;            
		}
	}

	&.custom-color-green input[type="checkbox"] {
		&:checked + span:before {
			color: $color-success;
			background-color: var(--font-white);
		}
	}

	&.custom-bgcolor-green input[type="checkbox"] {
		&:checked + span:before {
			color: var(--font-white);
			background-color: $color-success;
			border-color: darken($color-success, 8%);
		}
	}
}

.fancy-radio {
	margin-right: 10px;

	&,
	label {
		font-weight: normal;
	}

	input[type="radio"] {
		display: none;
		
		+ span {
			display: block;
			cursor: pointer;
			position: relative;

			i {
				@include inline-block;
				@include border-radius(8px);

				position: relative;
				bottom: 1px;
				content: "";
				border: 1px solid var(--border-color);
				width: 18px;
				height: 18px;
				margin-right: 5px;
			}
		}

		&:checked + span i:after {
			@include border-radius(50%);

			display: block;
			position: relative;
			top: 3px;
			left: 3px;
			content: '';
			width: 10px;
			height: 10px;
			background-color: #7b848c;
		} 
	}

	&.custom-color-green input[type="radio"] {
		&:checked + span i:after {
			background-color: var(--primary-color);
		}
	}

	&.custom-bgcolor-green input[type="radio"] {
		&:checked + span i {
			background-color: var(--primary-color);
			
			&:after {
				background-color: var(--font-white);
			} 
		}
	}
}

// Bootstrap radio btn style
.custom-control-input:checked~.custom-control-label::before{
	border-color: var(--primary-color);
    background-color: var(--primary-color);
}

/* fancy checkbox and fancy radio */
.input-group-addon {
	.fancy-radio,
	.fancy-checkbox {
		margin: 0;
		position: relative;
		top: 1px;
	}

	.fancy-radio input[type="radio"] + span i,
	.fancy-checkbox input[type="checkbox"] + span {
		margin: 0;

		&:before {
			margin: 0;
		}
	}
}
.input-group-text{
	background-color: var(--card-color);
	border-color: var(--border-color);
	color: var(--text-muted);
}
.form-control {
	@include box-shadow(none);	
	background-color: var(--card-color);
	border-color: var(--border-color);
	font-size: $font-size;
	height: auto;

	&:focus {
		@include box-shadow(none);
		outline: none;
		border-color: var(--primary-color);
	}
}

.help-block {
	font-size: 13px;
	color: var(--text-muted);
}

/* multiselect checkbox */
.multiselect-custom + .btn-group ul.multiselect-container {
	> li {
		> a {
            padding: 5px 15px;
            display: block;
            outline: none;

			label.checkbox {
                padding: 0;
                font-size: 14px;

				&:before {
					@include inline-block;

					position: relative;
					bottom: 1px;
					content: "";
					width: 16px;
					height: 16px;
					margin-right: 10px;
					border: 1px solid var(--border-color);
					background-color: #fafafa;
				}

				input[type="checkbox"] {
					display: none;
				}
			}
		}

		&.active > a {
			label.checkbox:before {
				font-family: 'FontAwesome';
				content: '\f00c';
				font-size: 12px;
				color: #99A1A7;
				text-align: center;
				line-height: 15px;
				background-color: var(--font-xlight);
			}
        }
        &.active{
            .checkbox{
                color: var(--font-white);                
            }
        }
        &:hover{
            background-color: var(--bg-hc-color);
        }
	}
}

/* multiselect radio */
.multiselect-custom + .btn-group ul.multiselect-container {
	> li {
		> a {
            padding: 5px 15px;
            display: block;
            outline: none;

			label.radio {				
                padding: 0;

				&:before {
					@include inline-block;
					@include border-radius(8px);

					position: relative;
					bottom: 1px;
					content: "";
					width: 16px;
					height: 16px;
					margin-right: 10px;
					border: 1px solid var(--border-color);
					background-color: #fafafa;
				}

				input[type="radio"] {
					display: none;
				}
			}
		}

		&.active > a {
			label.radio:before {
				font-family: 'FontAwesome';
				content: '\f111';
				color: var(--text-muted);
				font-size: 8px;
				text-align: center;
				line-height: 15px;
			}
        }

        &.active{
            .radio{
                color: var(--font-white);                
            }
        }
        
        &:hover{
            background-color: var(--bg-hc-color);
        }
	}
}

.multiselect-search {
	@include border-right-radius(2px !important);
}

.btn.multiselect-clear-filter {
	@include box-shadow(none);

	position: absolute;
	top: 2px;
	right: 0;
	padding-left: 0;
	padding-right: 0;
	border: none;
	background: transparent;
	z-index: 9 !important;
	outline: none;

	&:hover,
	&:focus {
		background: transparent;
		outline: none;
	}
}

/* date picker */
.datepicker table tr td.active {
	&.active,
	&.highlighted.active,
	&.highlighted:active,
	&.active:active {
		background-color: var(--secondary-color);
	}
}

/* parsley js */
.parsley-errors-list {
	margin-top: 8px;
	
	li {
		color: $color-danger;
		font-size: 0.9em;
		margin-top: 3px;
	}
}

input.parsley-error, 
select.parsley-error, 
textarea.parsley-error {
	background-color: #fbf5f5;
	border-color: darken(#fbf5f5, 8%);
}

.form-control {
	&.parsley-error:focus {
		border-color: darken(#fbf5f5, 18%);
	}

	&.parsley-success:focus {
		border-color: darken(#DFF0D8, 16%);
	}
}

.md-editor > textarea {
	padding: 10px;
}

.dropify-wrapper {
	@include border-radius(3px);

	background-color: var(--font-xlight);
	color: var(--text-muted);
	border: 1px solid var(--border-color);

	.dropify-preview {
		background-color: var(--font-xlight);
	}
	.dropify-message{
		p{
			font-size: 14px;
		}
	}
	&:hover{
		background-image: linear-gradient(-45deg,var(--border-color) 25%,transparent 25%,transparent 50%,var(--border-color) 50%,var(--border-color) 75%,transparent 75%,transparent);
	}
}

select.form-control:not([size]):not([multiple]){height: 36px;}

.btn-toolbar>.btn-group {@extend .m-l-5;}

.c_multiselect{
	.btn-group{
		width: 100%;
		.btn{
			width: 100%;
			text-align: left;
		}
	}
	.dropdown-menu{
		width: 100%;
		background: var(--card-color);
		border-color: var(--border-color);

		.multiselect-group{
			color: var(--font-color);
		}
	}
	.checkbox{
		color: var(--font-color);
	}
	.dropdown-toggle::after{
		right: 15px;
		position: absolute;
		top: 15px;
	}
}
.bootstrap-tagsinput{
	background-color: var(--card-color);
	border: 1px solid var(--border-color);
}
.cd-horizontal-timeline{
	color: var(--font-color);
	.filling-line{
		background-color: var(--primary-color)
	}
	
	.events {
		a.selected{
			color: var(--primary-color);
			&:after{
				background-color: var(--primary-color);
				border-color: var(--primary-color);
			}
		}
		a.older-event::after{
			border-color: var(--primary-color);
		}
	}
}
.modal-content{
	background-color: var(--card-color);
	border-color: var(--border-color);
	.modal-header,
	.modal-footer{
		border-color: var(--border-color);
	}
}