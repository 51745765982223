.event-name {    
    h6{
        @extend .m-b-0;
        text-transform: capitalize;
    }
    h4{
        font-size: 25px;
        line-height: 24px;
        @extend .m-b-0;
        @extend .m-t-0;

        span{
            @extend .displayblock;
            font-size: 10px;
            line-height: 12px;
            &:first-child {
                @extend .m-t-5;
            }
        }
    }
    p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        @extend .displayblock;
        @extend .m-b-0;
    }
    address{
        font-size: 11px;
        @extend .m-b-0;
        @extend .m-t-5;
        i{
            font-size: 12px;
            @extend .m-r-5;
        }
    }
}

.event-control {
    background-color: var(--font-xlight);
    font-size: 13px;
    cursor: pointer;
    
    &:hover {
        background-color: var(--font-white);
    }
    a {
        color: var(--font-color);
        cursor: pointer;
        opacity: .5;
        &:hover {
            opacity: 1;
        }
    }
    &[class*='bg-'] {
        &:not(.bg-default):not(.bg-white) {
            a {
                color: var(--font-color) !important;
                opacity: 1;
                &:hover {
                    color: var(--font-white) !important;
                }
            }
        }
    }
}

.fc-overlay {
    display: none;
    position: absolute;
    min-width: 260px;
    padding: 15px 0;
    top: auto;
    left: 50%;
    margin-left: -150px;
    z-index: 1000;
    color: var(--font-color) !important;

    &.left {
        left: 100%;
        top: -15px;
        padding: 0 10px;
        margin: 0;
    }
    &.right {
        left: auto;
        right: 100%;
        top: -15px;
        padding: 0 10px;
        @extend .margin-0;
    }
    .panel {
        padding: 10px;
    }
}

.fc {
    .fc-toolbar {
        @extend .p-t-10;
        @extend .p-b-10;
        @extend .margin-0;
        color: var(--font-color);
        background: transparent;
        border-radius: 0;        

        h2 {
            @extend .p-t-5;
            @extend .margin-0;
            text-transform: uppercase;
            font-size: 16px;
        }
        .fc-today-button, .fc-state-default{
            background: var(--secondary-color);
    	    border-color: var(--secondary-color);
            font-size: 14px;           
            padding: 8px 18px;
            height: auto;                
            border: none;
            box-shadow: none;
            text-shadow: none;
        }        
    }

    .fc-view-container {
        .fc-event {
            padding: 3px 5px;
            font-size: 12px;
            border-radius: $border-radius-small;            
            border: 0;
            color: var(--font-xlight);

            &.fc-event-end:not(.fc-event-start) {
                border: 0!important;
            }

            &:focus,
            &:hover,
            &:active {
                z-index: 1000;
                color: var(--font-white);
                .fc-overlay {
                    @extend .displayblock;
                }
            }
        }
        .fc-view {
            &.fc-basic-view,
            &.fc-agenda {
                >table {
                    >thead {
                        tr {
                            th,
                            td {
                                &.fc-widget-header {
                                    border: 1px solid var(--font-xlight);
                                    padding: 10px;
                                    color: var(--font-color);                      
                                }
                                &.fc-agenda-gutter {
                                    @extend .padding-0;
                                }
                            }
                            td {
                                &.fc-widget-header {
                                    @extend .padding-0;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                &.fc-widget-content {
                                    border: 0;
                                    border-bottom: 1px solid var(--font-xlight);;
                                    border-right: 1px solid var(--font-xlight);;
                                    &.fc-state-highlight {
                                        background: var(--white-color);
                                    }
                                }
                                &.fc-day-number {
                                    padding: 5px 10px;
                                    margin: 5px;
                                    color: var(--font-color);
                                    font-size: 15px;
                                    
                                    &.fc-state-highlight {
                                        background-color: $red;
                                        color: var(--font-white);
                                        @include border-radius(50%);
                                        padding: 4px 6px;
                                        float: right;
                                    }
                                }
                                &:first-child {
                                    &.fc-widget-content {
                                        border-left: 1px solid var(--font-xlight);;
                                    }
                                }
                            }
                        }
                    }
                }
                .fc-body {
                    .fc-row .fc-bg {
                        border-bottom: 1px solid var(--font-xlight);;
                    }
                    .fc-row:last-of-type .fc-bg {                        
                        border-bottom: 0;
                    }
                }
                .fc-agenda-allday {
                    .fc-agenda-axis,
                    .fc-agenda-gutter {
                        border-left: 1px solid var(--font-xlight);;
                        border-right: 1px solid var(--font-xlight);;
                        background-color: var(--font-xlight);
                        color: var(--font-color);
                        font-size: 12px;
                        font-weight: 100;
                        padding: 10px 5px;
                    }
                }
                >table {
                    >tbody {
                        >tr {
                            >td {
                                &.fc-state-highlight {
                                    background: var(--font-xlight);
                                }
                                &.fc-sat {
                                    border-right: 0;
                                }
                            }
                        }
                    }
                }
                .fc-agenda-slots {
                    tr {
                        line-height: 35px;
                        th {
                            border: 0;
                            border-left: 1px solid var(--font-xlight);;
                            border-right: 1px solid var(--font-xlight);;
                            background-color: var(--font-xlight);
                            color: var(--font-color);
                            font-size: 12px;
                            font-weight: 100;
                            padding-top: 10px;
                        }
                        td.fc-widget-content {
                            border-bottom: 1px solid var(--font-xlight);;
                            line-height: 50px;
                            &:last-child {
                                border-right: 0;
                            }
                        }
                        &.fc-minor {
                            td.fc-widget-content {
                                border-bottom: 1px solid var(--font-xlight);;
                            }
                        }
                    }
                }
                .fc-agenda-divider {
                    border: 1px solid var(--font-xlight);;
                    .fc-agenda-divider-inner {
                        background-color: var(--font-xlight);
                        height: 3px;
                    }
                }
            }
        }
        .fc-list-view{
            .fc-list-item{
                color: var(--font-color);
            }
        }
        .fc-other-month{
            background: var(--font-xlight);
        }

        .fc-basic-view .fc-day-number, .fc-basic-view .fc-week-number{
            float: left;
            padding: 10px 0 5px 10px;
        }
    }    

    .fc-popover{
        box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.2);
        border: none;
        padding: 10px;
        border-radius: $border-radius-large;
        .fc-event-container{
            padding: 0;
        }
        .fc-event{
            padding: 4px 8px;
            margin: 2px 0px;
        }
    
    }

    .fc-agendaWeek-view,
    .fc-agendaDay-view,
    .fc-listWeek-view{
        .fc-head{
            .fc-widget-header{
                span{
                    padding: 10px;
                    display: inline-block;
                }
            }
        }

        .fc-unthemed{
            td{
                border-color: #f4f7f6;                
            }
        }
    }    
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead{
    border-color: var(--border-color);
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header{
    background: var(--card-color);
}

@media only screen and (max-width: 420px) {
    .fc-toolbar .fc-right{ display: none;}
    .fc-toolbar .fc-left{ width: 100%; margin-bottom: 15px;}
    .fc .fc-toolbar .fc-today-button{ float: right;}
}