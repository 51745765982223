// import google fonts
@import "font/fonts";
@import "font/simple-line-icons";
@import "font/weather-icons";

//
@import "mixins/responsive";
@import "mixins/clearfix";
@import "mixins/css3";
@import "mixins/elements-color-custom";
@import "mixins/animation";

// Project Core SCSS
@import "base/variables";
@import "base/general";
@import "base/rtl";

// Mesti Menu SCSS
@import "metisMenu/metisMenu";

// Bootstrap Custome SCSS
@import "partials/badges";
@import "partials/layout";
@import "partials/card";
@import "partials/navigation";
@import "partials/leftbar";
@import "partials/forms";
@import "partials/authentication";
@import "partials/buttons";
@import "partials/elements";
@import "partials/accordion";
@import "partials/elements-bootstrap";
@import "partials/charts";
@import "partials/social-brands";
@import "partials/typography";
@import "partials/helpers";
@import "partials/mediaobject";
@import "partials/formwizard";
@import "partials/tables";

// Project Widgets SCSS
@import "pages/widgets";

// More Pages SCSS
@import "pages/page-profile";
@import "pages/page-profile2";
@import "pages/timeline";
@import "pages/calendar";
@import "pages/file_manager";
@import "pages/pricing";
@import "pages/invoice";
@import "pages/iot";

// More Admin Templates SCSS
@import "pages/hr";
@import "pages/hospital";
@import "pages/realestate";
@import "pages/university";

.sidebar-nav .metismenu > li.active > a {
    color: #000000;
    border-radius: 20px 20px 0 0;
}

.sidebar-nav .metismenu > li.active {
    border-radius: 10px;
    background-color: var(--primary-color);
    // border: 2px solid var(--primary-color);
}

.navbar-fixed-top {
    transition: all 0.3s ease-in-out;
    padding: 5px 15px;
    position: fixed;
    top: 0px;
    left: 280px;
    z-index: 99;
    width: calc(100% - #{$sidebar-width});
    background: var(--primary-color);
}

.layout-fullwidth #wrapper .navbar-fixed-top {
    width: 100%;
    left: 0;
}

.navbar-fixed-top .navbar-brand a {
    font-weight: 700;
    color: var(--dark-color);
    font-size: 22px;
}

.nav-tabs-new2 > li > a.active {
    border-bottom: 2px solid var(--primary-color) !important;
    background-color: transparent;
    color: var(--dark-color);
}

.nav-tabs-new2 > li > a:hover,
.nav-tabs-new2 > li > a:focus {
    color: var(--dark-color);
    border-bottom: 2px solid var(--primary-color) !important;
}

.card {
    transition: all 0.3s;
    background: var(--card-color);
    border: transparent;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}

.card:hover,
.card:focus-within,
.card:focus {
    border-color: transparent;
}

.card-salepage-body {
    border: 1px dashed var(--primary-color3);
}

.card-header-body {
    background-color: var(--primary-color3);
}

.card-header-flashsale {
    background-color: var(--primary-color3);
}

.card-body-flashsale {
    border: 1px dashed var(--primary-color3);
}
.card-header-forms {
    background-color: var(--primary-color3) !important;
}

.card-body-forms {
    border: 1px dashed var(--primary-color3) !important;
}

.card-salepage-body:hover,
.card-salepage-body:focus-within,
.card-salepage-body:focus {
    border: 1px dashed var(--primary-color3);
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid var(--primary-color);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background-color: var(--primary-color);
}

.modal-content .modal-header,
.modal-content,
.modal-footer {
    border-color: transparent;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    padding-bottom: unset;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 0px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    padding-top: unset;
}

label {
    color: var(--primary-color2);
}

.help-block-alert {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:focus-within {
    color: var(--dark-color);
}

.user-account {
    margin: 10px 0;
}

.user-account ul {
    text-align: left;
}

.fancy-checkbox input[type="checkbox"] + span:before {
    display: inline-block;
    vertical-align: middle;
    vertical-align: auto;
    zoom: 1;
    position: relative;
    bottom: 1px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    content: "";
    border: 1px solid var(--border-color);
    border-radius: 3px;
}

.form-date {
    .mx-datepicker {
        width: 100% !important;
    }
}

.card-how-to {
    margin-bottom: 5px;
}

.card-header-how-to {
    background-color: #fff;
    border: 1px solid var(--primary-color);
}

.card-body-manage-salpage {
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
}

.btn-link-how-to {
    color: #000000;
}
.btn-link-how-to:hover {
    color: #000000;
}
.btn-how-to.focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}
.fa-chevron-up {
    transition: all 0.3s ease;
}
.btn-nav-accordion.collapsed .fa-chevron-up {
    transform: rotate(180deg);
}

.checkout-input-placeholder::placeholder {
    color: #a1a1a1;
    font-weight: lighter;
}

label.required::after {
    content: " *";
    color: red;
}

@media (min-width: 1200px) {
    section.checkout .container {
        max-width: 820px;
        background-color: #f7f7f7;
    }
}
section.checkout .checkout-container {
    padding-bottom: 0px;
}
section.checkout .checkout-item {
    height: auto;
    margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 992px) {
    section.checkout .checkout-item {
        margin: 0;
    }
}
section.checkout .checkout-content {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
}
section.checkout .checkout-content__item {
    margin: 10px 0;
}
section.checkout .checkout-content__item .table thead th {
    border-top-color: white;
}
section.checkout .checkout-content__item .table td {
    border-top-color: white;
}
section.checkout .checkout-content__item .card.card-payment {
    border: 2px solid #000000;
    margin-left: 1rem;
    margin-bottom: 1em;
}
section.checkout .checkout-content__item .text-payment {
    margin-bottom: 8px;
}

.card-bank-account-list {
    padding: 10px;
    border: 1px solid;
    border-radius: 0.2rem;
}
.card-bank-account-title {
    // margin-bottom: 2rem;
}
.account-code-text,
.holder-name-text {
    font-size: 13px;
}
i.bank.bank-logo {
    width: 30px;
    height: 30px;
}
.preview__image_slider {
    object-fit: contain;
}
.contain-captcha {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  flex-flow: column;
  align-items: center;
}
.invalid-recaptcha{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  margin-left: 20px;
  color: #dc3545;
}
@media (min-width: 0px) and (max-width: 992px) {
    .preview__image_slider {
        height: 300px;
    }
}
@media (min-width: 1200px) {
    .preview__image_slider {
        height: 500px;
    }
}
