﻿
.appliances-grp {
	.body {
		clear: both;
		overflow: hidden;
	}
	.icon {
		float: left;
		width: 40px;
		margin-right: 15px;

		img {
			max-width: 100%;
		}
	}
	.content {
		float: right;
		width: calc(100% - 60px);

		h6 {
			font-size: 15px;
			line-height: 20px;
			font-weight: $font-weight-600;

			span {
				float: right;
				font-size: 14px;
				line-height: 20px;
				font-weight: $font-weight-400;
			}
		}
		p {
			font-size: 13px;
			line-height: 20px;
			margin-bottom: 0;

			span {
				float: right;
			}
		}
	}
}
