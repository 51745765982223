/* Authentication forms */
.auth-main{

    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: var(--primary-color);

        @include max-screen($break-xsmall) {
            width: 100%;
        }
    }
    &:after{
        content: '';
        position: absolute;
        right:0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
    }
    .card{
        padding:10px;
        margin-bottom: 0;
    }
}


.auth-box {
	width: 380px;
	height: auto;
    margin-left: auto;
    margin-right: auto;

	.top{
        @extend .m-b-30;
        img{
            width: 130px;
        }        
    }

    .card .header {
        border-bottom: 1px solid var(--border-color);
        padding-top: unset;
    }

    .card .header, .card .body {
        color: var(--dark-color);
        padding: 10px;
        position: relative;
        box-shadow: none;
        margin-bottom: 0;
    }

    .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 10px;
    }

	.form-auth-small {
		.btn{
            @extend .m-t-20;
		}

		.element-left {
			float: left;
		}

		.element-right {
			float: right;
		}

		.bottom {
            @extend .m-t-20;
			@extend .align-center;
		}
        .terms-policy {
            @extend .m-t-20;
			@extend .align-center;
            font-weight: 700;
		}

        .checkbox__terms-policy {
            @extend .m-t-20;
            display: flex;
            align-items: center;
		}
        a.terms, span.text__zavanna {
            font-weight: 400;
        }
    }
    
    .lead {
        @extend .m-b-0;
        font-size: 18px;
        font-weight: 600;
    }

	.helper-text {
        color: var(--text-muted);
        @extend .displayblock;
	}

	.btn-signin-social {
        @extend .displayblock;
        @extend .m-b-5;
		padding: 10px 22px;
		width: 100%;
		background-color: var(--font-white);
		border-color:  var(--border-color);

		&:hover,
		&:focus {
			background-color: var(--font-xlight);
		}

		i {
			font-size: 16px;
			margin: 5px;
		}
	}
	@include max-screen($break-medium) {
        width: 80%;
        margin: 0 auto;
	}

	@include max-screen($break-xsmall) {
        width: 90%;
	}
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 2px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.text__accept {
    color: #333333;
    font-weight: 700;
}