/* Side Bar Menu */
.sidebar {
    @include transition(all 0.3s ease-in-out);
    background: var(--card-color);
    width: $sidebar-width;
    height: 100vh;
    box-shadow: inset -2px -2px 8px 0 var(--box-shadow-light);
    position: fixed;
    left: 0;
    padding: 0 20px;
    z-index: 11;
    border-right: 1px solid var(--border-color);

    overflow-y: scroll;
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // IE 10+

    &::-webkit-scrollbar {
        // WebKit
        width: 5px;
    }

    &.open {
        left: 0 !important;
        z-index: 999 !important;

        .btn-toggle-offcanvas {
            display: block;
            position: absolute;
            left: 230px;
            top: 24px;
            outline: 0;
            border: 0;
        }
    }

    &.primary {
        box-shadow: none;
        background: var(--primary-color);
        color: var(--font-white);
        hr {
            opacity: 0.2;
        }
        .sidebar-nav {
            .metismenu {
                > li.active {
                    border-color: var(--white-color);
                    > a {
                        color: var(--font-white);
                    }
                }
                ul a {
                    color: var(--font-white);
                    opacity: 0.8;
                }
            }
        }
        .right_chat .media {
            .name {
                color: var(--font-white);
            }
            .message {
                color: var(--font-white);
                opacity: 0.6;
            }
        }
        .fancy-checkbox {
            input[type="checkbox"]:checked + span:before {
                border-color: var(--white-color);
                background: var(--white-color);
            }
        }
    }

    .btn-toggle-offcanvas {
        @include transition(all 0.3s ease-in-out);
        display: none;
    }

    .nav-tabs {
        @include border-radius(30px);
        background: var(--dark-color);
        padding: 0 5px;
        border: 0;
        margin-bottom: 15px;

        .nav-link {
            border: 0;
            font-weight: $font-weight-400;
            color: var(--font-light);

            &.active,
            &:hover,
            &:focus {
                background-color: transparent;
                color: var(--primary-color);
            }
        }
    }
}

@media (min-width: 0px) and (max-width: 1279px) {
    .mobile-sidebar {
        .sidebar {
            left: -280px !important;
        }
        #main-content {
            width: 100% !important;
        }
        .navbar {
            width: 100% !important;
            left: 0 !important;
        }
    }
}

.layout-fullwidth #wrapper {
    .navbar-fixed-top {
        width: calc(100% - #{$sidebar-icon});
        left: 0;
    }
    .sidebar {
        left: -$sidebar-width;

        &.ps {
            overflow: visible !important;
        }
    }
    #main-content {
        width: calc(100% - #{$sidebar-icon});
    }
}

.user-account {
    margin: 20px 0;
    @extend .align-left;

    .user-photo {
        width: 50px;
        vertical-align: top;
        border: 2px solid var(--border-color);
        @extend .m-r-10;
    }

    .user-name {
        @extend .displayblock;
        color: inherit;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .dropdown {
        @extend .inlineblock;
        @extend .align-left;
        @extend .m-t-5;

        .dropdown-menu {
            transform: none !important;
            border: none;
            box-shadow: 0px 2px 20px 0px var(--box-shadow);
            padding: 15px;
            background: var(--dark-color);
            border-radius: $border-radius-large;

            li.divider {
                border-bottom: 1px solid var(--border-color);
                margin: 10px 0;
            }

            a {
                @extend .displayblock;
                padding: 10px;
                font-size: 14px;
                color: var(--font-light);

                &:hover {
                    color: var(--primary-color);

                    i {
                        color: var(--primary-color);
                    }
                }

                i {
                    @extend .m-r-10;
                    font-size: 14px;
                }
            }
        }
    }
    ul {
        text-align: center;
    }
}

.sidebar-nav {
    ul {
        @extend .padding-0;
        @extend .margin-0;
        list-style: none;
    }

    .metismenu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > li {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 0%;
            flex: 1 1 0%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            position: relative;
            border: 1px solid transparent;

            &.active {
                @include border-radius(15px);
                border-color: var(--primary-color);

                > a {
                    color: var(--primary-color);
                    border-radius: 20px 20px 0 0;
                }
                ul {
                    border-radius: 0 0 20px 20px;
                    margin-bottom: 15px !important;
                }
            }

            .active > a {
                font-weight: $font-weight-700;
            }

            i {
                width: 28px;
            }
        }

        a {
            @include transition(all 0.3s ease-out);
            @extend .displayblock;
            @include border-radius(50px);
            color: var(--dark-color);
            position: relative;
            padding: 13px 20px;
            outline-width: 0;
            font-size: 15px;

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }

        ul a {
            color: var(--font-color);
            font-size: $font-size;
            position: relative;
            padding: 5px 15px 5px 49px;

            &::before {
                content: "--";
                position: absolute;
                left: 21px;
                top: 4px;
            }
        }

        ul ul {
            position: relative;

            &::before {
                position: absolute;
                left: 27px;
                height: 100%;
                width: 1px;
                content: "";
                background: var(--border-color);
            }
            a {
                padding: 5px 15px 5px 60px;

                &:before {
                    left: 46px;
                }
            }
        }

        ul.collapse a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .has-arrow::after {
            color: var(--text-muted);
        }
    }
}

ul.choose-skin {
    @extend .m-b-0;
    display: flex;
    justify-content: flex-start;

    li {
        margin-right: 5px;
        div {
            @include border-radius(22px);
            @extend .inlineblock;
            height: 22px;
            width: 22px;
            position: relative;

            &.purple {
                background: $purple;
            }
            &.blue {
                background: $blue;
            }
            &.cyan {
                background: $cyan;
            }
            &.green {
                background: $green;
            }
            &.orange {
                background: $orange;
            }
            &.blush {
                background: $blush;
            }
            &.red {
                background: $red;
            }
        }

        &.active {
            div {
                &::before {
                    position: absolute;
                    color: var(--font-white);
                    font-size: 12px;
                    top: 2px;
                    left: 5px;
                    content: "\f00c";
                    font-family: "FontAwesome";
                }
            }
        }
    }
}

.question {
    li {
        padding: 3px 0;

        &.menu-heading {
            @extend .m-t-15;
        }

        a {
            @extend .p-l-15;
            position: relative;

            &:before {
                content: "--";
                left: 0;
                position: absolute;
            }
        }
    }
    .help-search-form {
        i {
            font-size: 12px;
        }
    }
}
