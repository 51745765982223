/* Helpers */

//Margin
@for $i from -25 through 25 {
    .m-l-#{$i * 5} {
        margin-left: #{$i * 5}px;
    }

    .m-t-#{$i * 5} {
        margin-top: #{$i * 5}px;
    }

    .m-r-#{$i * 5} {
        margin-right: #{$i * 5}px;
    }

    .m-b-#{$i * 5} {
        margin-bottom: #{$i * 5}px;
    }
}

.margin-0 {
    margin: 0;
}

@for $i from 0 through 25 {
    .p-l-#{$i * 5} {
        padding-left: #{$i * 5}px;
    }

    .p-t-#{$i * 5} {
        padding-top: #{$i * 5}px;
    }

    .p-r-#{$i * 5} {
        padding-right: #{$i * 5}px;
    }

    .p-b-#{$i * 5} {
        padding-bottom: #{$i * 5}px;
    }
}

.padding-0 {
    padding: 0 !important;
}

@for $i from 5 through 49 {
    .font-#{$i + 1} {
        font-size: #{$i + 1}px;
    }
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}

.padding-25 {
    padding: 25px !important;
}

hr {
    border-color: var(--border-color);
}

.inlineblock {
    display: inline-block;
}
.displayblock {
    display: block;
}
.displaynone {
    display: none;
}
.overflowhidden {
    overflow: hidden;
}
.z-index {
    z-index: 9999;
}

.p-10 {
    padding: 10px !important;
}

.l-green {
    background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important;
}
.l-pink {
    background: linear-gradient(45deg, #ffc0cb, #fbc1cb) !important;
}
.l-turquoise {
    background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
}
.l-cyan {
    background: linear-gradient(45deg, #49cdd0, #00bcd4) !important;
}
.l-khaki {
    background: linear-gradient(45deg, #f0e68c, #fdf181) !important;
}
.l-coral {
    background: linear-gradient(45deg, #f08080, #f58787) !important;
}
.l-salmon {
    background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
}
.l-blue {
    background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
}
.l-seagreen {
    background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
}
.l-amber {
    background: linear-gradient(45deg, #fda582, #f7cf68) !important;
}
.l-blush {
    background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
}
.l-parpl {
    background: linear-gradient(45deg, #a890d3, #edbae7) !important;
}
.l-slategray {
    background: linear-gradient(45deg, #708090, #7c8ea0) !important;
}

.primary-bg {
    background: var(--primary-color);
}
.primary-text {
    color: var(--primary-color);
}
.secondary-bg {
    background: var(--secondary-color);
}
.secondary-text {
    color: var(--secondary-color);
}

.width35 {
    width: 35px;
}
